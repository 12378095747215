import React, { useState, useEffect, useRef, ClipboardEvent } from "react";
import "./App.css";
import {
  Input,
  Row,
  Col,
  Tooltip,
  Modal,
  Upload,
  Button,
  Select,
  notification,
} from "antd";
import axios from "axios";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import {
  BaseUrl,
  REACT_APP_ACESS_KEY_ID,
  REACT_APP_SECRET_ACESS_KEY,
  MicroUrl3,
} from "./Constants/Constant";
import { parseUrlsToLinks } from "./Functions/CommonFunction";
import HTMLReactParser from "html-react-parser";
import TypeWriter from "./TypeWriter/TypeWriter";
import AWS from "aws-sdk";
import { Document, Page, pdfjs } from "react-pdf";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  GlobalOutlined,
  UploadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import ChatBotSider from "./ChatBotSider";
import {
  faMicrophone,
  faPaperPlane,
  faPlus,
  faCopy,
  faThumbsDown,
  faThumbsUp,
  faVolumeDown,
  faVolumeHigh,
  faVolumeLow,
  faPlay,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import copy from "clipboard-copy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import annyang from "annyang";
import micAnimation from './lottie/Mic-animation.json'
import Lottie from "react-lottie-player";
import { message } from "antd/es";

const Chatbot = ({ onClose, userId, textToCopy }) => {
  const { TextArea } = Input;
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  // When the iframe is closed (you can trigger this wherever you determine that the iframe is closed)
  // window.parent.postMessage('iframe_closed', 'http://localhost:9008');
  const [messages, setMessages] = useState([]);
  const [likeClass, setLikeClass] = useState("");
  const [dislikeClass, setDisLikeClass] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [BubbleClr, setBubbleClr] = useState("#6B24D6");
  const [Alignment, setAlignment] = useState("Left");
  const [ConvoClr, setConvoClr] = useState("#fff");
  const [ChatIcon, setChatIcon] = useState("");
  const [Loading, setLoading] = useState(true);
  const [BotName, setBotName] = useState("");
  const [welcomeMsg, setwelcomeMsg] = useState("");
  const [Loader, setLoader] = useState(false);
  const [reference, setReference] = useState(false);
  const [speakStates, setSpeakStates] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewFilename, setPreviewFilename] = useState("");
  const [file, setfile] = useState([]);
  const [errorNotification, setErrorNotification] = useState(null);
  const [Disabled, setDisabled] = useState(false);
  const [DOCURL, setDOCURL] = useState("");
  const [PageNumber, setPageNumber] = useState("");
  const [Suggession, setSuggession] = useState(["hi", "hello"]);
  const [Feedback, setFeedback] = useState([]);
  const [ThumbsDownres, setThumbsDownres] = useState("");
  const [SelectedFeedback, setSelectedFeedback] = useState("Out of content");
  const [comments, setComments] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [ChatImg, setChatImg] = useState("");
  const [type, setType] = useState("");
  const [UserId, setUserId] = useState(userId)
  const [acceptFile, setAcceptFile] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [transcription, setTranscription] = useState("");
  const [MailId, setMailId] = useState('')
  const [MailContent, setMailContent] = useState('')
  const [LeadId, setLeadId] = useState('')

  const recognitionRef = useRef(null);

  const [Index, setIndex] = useState("");
  let url = window.location.href;
  let BotID = url.split("/").slice(-1);
  const botID = BotID[0];

  const [api, contextHolder] = notification.useNotification();
  //=================== location API ================//

  const geolocationAPI = navigator.geolocation;
  useEffect(() => {
    let UserIdURL = `${BaseUrl}/get_userid_from_botid`

    //Getting user ID from bot ID for UAT

    // let UserIdURL = `https://api-uat.precium.ai/get_user_id?bot_id=${botID}`

    let body = {
      "bot_id": botID
    }
    axios
      .post(UserIdURL, body)
      .then((res) => {

        console.log(res, "ress")
        setUserId(res.data.user_id)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [])
  const getUserCoordinates = () => {
    // debugger;
    if (!geolocationAPI) {
      console.log("Geolocation API is not available in your browser!");
    } else {
      console.log("gets in here");
      geolocationAPI.getCurrentPosition(
        (position) => {
          const { coords } = position;
          let lattitude = parseFloat(coords.latitude);
          let longtitude = parseFloat(coords.longitude);

          console.log(coords.latitude, coords.longitude, "llllllll");
          let body = {
            latitude: lattitude,
            longitude: longtitude,
          };

          axios
            .post(`https://api-dev.precium.ai/get_location`, body)
            .then((res) => {
              console.log(res, "location");
            })
            .catch((err) => {
              console.log(err);
            });
        },
        (error) => {
          console.log("Something went wrong getting your position!");
        }
      );
    }
  };
  useEffect(() => {
    if (type === "teaching-assistant" || type === "agreement-assistant") {
      // setFileFormats('.pdf');
      setAcceptFile(".pdf");
    } else if (type === "data-assistant") {
      // setFileFormats('.csv')
      setAcceptFile(".csv");
    } else {
      // setFileFormats('.pdf, .docx, .txt, .mp3, .mp4, .weba, .wav, .ppt, .pptx');
      setAcceptFile(
        ".pdf, .docx, .txt, .mp3, .mp4, .weba, .wav, .ppt, .pptx, !.xlsx"
      );
    }
  }, [type]);

  console.log(acceptFile, "acceptFile");

  // useEffect(() => {
  //   getUserCoordinates();
  // }, [])

  AWS.config.update({
    region: "us-east-1",
    credentials: new AWS.Credentials({
      accessKeyId: REACT_APP_ACESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
    }),
  });

  const s3 = new AWS.S3();
  const fileType = previewFilename.split(".").pop();

  const uploadFileToS3 = () => {
    const userMessage = {
      content: previewFilename,
      sender: "user",
      side: "left",
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setLoader(true);
    const params = {
      Bucket: "preciumweb-dev",
      Key: `dev/${UserId}/${BotID}/${previewFilename}`,
      Body: file,
    };

    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        console.log(err, data, "chan");
        if (err) {
          reject(err);
          setLoader(false);
          setPreviewVisible(false);
          const userMessage = {
            content: "error",
            sender: "answer",
            side: "left",
          };

          setMessages((prevMessages) => [...prevMessages, userMessage]);
        } else {
          resolve(data);
          setLoader(false);
          setPreviewVisible(false);
          let body = {
            urls: [
              `https://ded2w7tw5rnvn.cloudfront.net/dev/${UserId}/${BotID}/${previewFilename}`,
            ],
          };
          axios
            .post(`${BaseUrl}/upload_S3_file`, body)
            .then((res) => {
              console.log(res, "s3 res");
              setSuggession([]);
              const userMessage = {
                content: "Uploaded successfully",
                sender: "bot",
                side: "left",
                url: null,
                pageNo: null,
              };
              setMessages((prevMessages) => [...prevMessages, userMessage]);
            })
            .catch((err) => {
              setSuggession([]);
              const userMessage = {
                content: "Uploaded Failed",
                sender: "bot",
                side: "left",
                url: null,
                pageNo: null,
              };
              setMessages((prevMessages) => [...prevMessages, userMessage]);
              console.log(err, "s3err");
            });
        }
      });
      setPreviewVisible(false);
    });
    // .catch((err) => {
    //   console.log(err,"upload err")
    //   const userMessage = {
    //     content: 'error',
    //     sender: "answer",
    //     side: "left",
    //   };

    //   setMessages((prevMessages) => [...prevMessages, userMessage]);
    // })
  };
  useEffect(() => {
    axios
      .get(`${BaseUrl}/feedback`)
      .then((res) => {
        console.log(res, "res");
        setFeedback(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleLikeDisplay = (index) => {
    // console.log(index,'chan')
    setIsHovered(true);
    setHoveredRow(index);
    // setLikedRow(index);
  };

  const handleLikeOutDisplay = (index) => {
    setIsHovered(false);
    setHoveredRow(index);
  };

  const openNotificationWithIconError = (type) => {
    api["error"]({
      message: "Oops!!!",
      description: type,
    });
  };

  const isPdf = (file) => {
    return file.type === "application/pdf";
  };

  const isCsv = (file) => {
    return file.type === "text/csv" || file.name.endsWith(".csv");
  };

  useEffect(() => {
    if (errorNotification != null) {
      openNotificationWithIconError(errorNotification);
    }
  }, [errorNotification]);

  const handlePreview = (files) => {
    // debugger;
    const lastFileType = files.file.name.toLowerCase().split(".").slice(-1)[0];
    console.log(lastFileType, "uploadedfile");
    setPreviewVisible(true);

    if (type === "teaching-assistant" || type === "agreement-assistant") {
      // debugger;
      const csvFiles = files.fileList.filter((file) =>
        isPdf(file.originFileObj)
      );

      // if (allPdf) {
      if (csvFiles.length === files.fileList.length) {
        const csvFiles = files.fileList.map((item) => item.originFileObj);
        // console.log(csvFiles[csvFiles.length-1].name, 'csvFiles')
        setPreviewFilename(csvFiles[csvFiles.length - 1].name);
        setfile(csvFiles[csvFiles.length - 1]);
        setPreviewImage(files.file.url || files.file.thumbUrl);
      } else if (lastFileType === "pdf") {
        const csvFiles = files.fileList.map((item) => item.originFileObj);
        // console.log(csvFiles[csvFiles.length-1].name, 'csvFiles')
        setPreviewFilename(csvFiles[csvFiles.length - 1].name);
        setfile(csvFiles[csvFiles.length - 1]);
        setPreviewImage(files.file.url || files.file.thumbUrl);
      } else {
        // openNotificationWithIconError('You can only upload PDF files & other formats will not be uploaded!');
        setErrorNotification(
          "You can only upload PDF files & other formats will not be uploaded!"
        );
        setfile([]);
        setPreviewVisible(false);
        // setVectorError('You can only upload PDF files & other formats will not be uploaded!');
        // Set the state variable to include only valid CSV files
        // const validCsvFiles = csvFiles.map(item => item.originFileObj);
        // setfile(validCsvFiles[validCsvFiles.length-1]);
        // setPreviewFilename(validCsvFiles[validCsvFiles.length-1].name);
        // Optionally, you can reset the fileList to remove invalid files from the preview
        // const newFiles = [...files.fileList];
        // newFiles.splice(0, newFiles.length, ...csvFiles);
        // Update the fileList to include only valid files
        // files.fileList = newFiles;
      }
    } else if (type === "data-assistant") {
      // debugger;
      // const allCsv = files.fileList.every(file => isCsv(file.originFileObj));
      const csvFiles = files.fileList.filter((file) =>
        isCsv(file.originFileObj)
      );
      // console.log(isPdf(csvFiles[csvFiles.length-1]), 'csvFileslength')
      console.log(csvFiles, "csvFileslength");

      if (csvFiles.length === files.fileList.length) {
        const csvFiles = files.fileList.map((item) => item.originFileObj);
        setfile(csvFiles[csvFiles.length - 1]);
        setPreviewFilename(csvFiles[csvFiles.length - 1].name);
        setPreviewImage(files.file.url || files.file.thumbUrl);
      } else if (lastFileType === "csv") {
        const csvFiles = files.fileList.map((item) => item.originFileObj);
        setfile(csvFiles[csvFiles.length - 1]);
        setPreviewFilename(csvFiles[csvFiles.length - 1].name);
        setPreviewImage(files.file.url || files.file.thumbUrl);
      } else {
        // openNotificationWithIconError('You can only upload CSV files & other formats will not be uploaded!');
        setErrorNotification(
          "You can only upload CSV files & other formats will not be uploaded!"
        );
        setfile([]);
        setPreviewVisible(false);
        // const invalidFiles = files.fileList.filter(file => !isCsv(file.originFileObj));
        // const validCsvFiles = csvFiles.map(item => item.originFileObj);

        // Set the state variable to include only valid CSV files
        // setfile(validCsvFiles[validCsvFiles.length-1]);
        // setPreviewFilename(validCsvFiles[validCsvFiles.length-1].name);
        // Optionally, you can reset the fileList to remove invalid files from the preview
        // const newFiles = [...files.fileList];
        // newFiles.splice(0, newFiles.length, ...csvFiles);
        // Update the fileList to include only valid files
        // files.fileList = newFiles;
      }
    } else {
      const files1 = files.fileList.map((item) => item.originFileObj);
      setPreviewImage(files.file.url || files.file.thumbUrl);
      setPreviewFilename(files1[files1.length - 1].name);
      setfile(files1[files1.length - 1]);
    }
  };

  // ======================================================== //
  // const handlePreview = (file) => {
  //   console.log(file.file, "uploadedfile");
  //   setPreviewImage(file.file.url || file.file.thumbUrl);
  //   setPreviewFilename(file.file.name);
  //   setPreviewVisible(true);
  //   // convertToBase64(file.file.originFileObj)
  //   setfile(file.file.originFileObj);
  // };
  console.log("previewVisible:", previewVisible);

  const ImagePreviewModal = ({ visible, onCancel, imageUrl, filename }) => {
    console.log(filename, "fileee");
    return (
      <Modal
        className="Modal"
        open={visible}
        onCancel={onCancel}
        okText="submit"
        onOk={(filename) => uploadFileToS3(filename)}
      >
        <h4>{previewFilename}</h4>
        <lottie-player
          src="https://lottie.host/90850875-0857-40cf-9a98-d908a58adaca/ds8aYRnqZd.json"
          background="#FFFFFF"
          speed="1"
          style={{ width: 200, height: 200 }}
          loop
          autoplay
          direction="1"
          mode="normal"
        ></lottie-player>

        {/* <img src={imageUrl} alt={previewFilename} style={{ width: '100%' }} /> */}
        {/* <Progress percent={100} /> */}
      </Modal>
    );
  };
  const handleCancel = () => setPreviewVisible(false);

  useEffect(() => {
    let dynamicURL = `${BaseUrl}/display_bot_details`;

    // For UAT deployment changing dev url to UAT url only in this API

    // let dynamicURL = `https://api-uat.precium.ai/bot_full_details/`

    let body = {
      bot_id: botID,
    };

    axios.post(dynamicURL, body)
      .then((res) => {
        console.log(res, "res");

        if (res.data) {
          setAlignment(res.data.align_chat_bubble_button);
          setBubbleClr(res.data.chat_bubble_colour);
          setConvoClr(res.data.chat_conversation_colour);
          setChatIcon(res.data.chat_icon);
          setBotName(res.data.display_name);
          setwelcomeMsg(res.data.welcome_message);
          setReference(res.data.referance_page);
          setChatImg(res.data.profile_picture);
          setType(res.data.bot_type);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, []);

  console.log(type, "botTypeAssis");

  const handleClose = () => {
    onClose();
  };

  const [inputVal, setInputVal] = useState("");
  const [waveHeight, setWaveHeight] = useState(100);
  const [EbvLink, setEbvLink] = useState('')
  const chatWindowRef = useRef(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const startListening = () => {
    setIsListening(true);

    if (recognitionRef.current) {
      recognitionRef.current.start();
    }
  };

  const stopListening = () => {
    setIsListening(false);

    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
  };
  console.log(waveHeight, "waveHeight");
  const handleSpeechResult = (event) => {
    const { transcript } = event.results[0][0];

    const amplitude = transcript.length / 100;

    setInputVal(transcript);
    setWaveHeight(100 + amplitude * 50);
    // setIsListening(false);
  };
  useEffect(() => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = false;
    recognition.lang = "en-US";

    recognition.onresult = (event) => {
      handleSpeechResult(event);
    };

    recognition.onerror = (event) => {
      console.log("Speech recognition error:", event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      console.log("Speech recognition ended");
      setIsListening(false);
    };

    recognitionRef.current = recognition;

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, []);

  const handleSubmit = (e) => {
    setIsCopied(false);
    stopListening();
    // e.preventDefault();
    if (inputVal.trim() !== "") {
      sendMessage(inputVal);
      console.log("clicked");
      setInputVal("");
    }
  };

  const handleInput = (e) => {
    setIsCopied(false);
    setInputVal(e.target.value);
  };

  const handleKeySubmit = (e) => {
    // e.preventDefault();
    if (e.keyCode === 13 && !e.shiftKey) {
      handleSubmit();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  const sendMessage = (message) => {
    setDisabled(true);
    const userMessage = {
      content: message,
      sender: "user",
      side: "left",
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setLoader(true);
    // let VectorUrl = `${BaseUrl}/vector_retrieval`;
    let VectorUrl = `${MicroUrl3}/vector_retrieval`;

    // For UAT environment changing this API
    // let VectorUrl = `https://api-uat.precium.ai/vector_retrieval`

    let body = {
      user_id: UserId,
      bot_id: botID,
      user_prompt: "",
      question: message,
    };

    axios
      .post(VectorUrl, body)
      .then((res) => {
        setDisabled(true);
        console.log(res, "chan");
        setDisabled(false);
        const decoded = atob(res.data);
        const decodedData = JSON.parse(decoded);

       
        if (decodedData.status == 200) {
          if (decodedData.suggested_questions) {
            
            setSuggession(decodedData.suggested_questions);
          }
          console.log(
            decodedData.status,
            "decodedData",
            decodedData);
          if (decodedData.receiver_mail) {
            let missingInfo = decodedData.missing_info 
            console.log(typeof  decodedData.missing_info,"channnnn", missingInfo)
            setMailContent(decodedData.missing_info)
            setMailId(decodedData.receiver_mail)
            setLeadId(decodedData.lead_id)
          }
          if(decodedData.ebv_link){
            setEbvLink(decodedData.ebv_link)
          }
          setTimeout(() => {
            const botMessage = {
              content: parseUrlsToLinks(decodedData.answer),
              sender: "bot",
              side: "left",
              url: decodedData.link,
              pageNo: decodedData.page_no,
              DocUrl: decodedData.pdf_url,
              mailId:decodedData.receiver_mail,
              ebvLink:decodedData.ebv_link,
              copytext: decodedData.copy_text,
              file_name: decodedData.file_name,
              start_time: decodedData.start_time
            };

            setMessages((prevMessages) => [...prevMessages, botMessage]);
          });
        } else {
          setTimeout(() => {
            const botMessage = {
              content: decodedData.answer,
              sender: "bot",
              side: "left",
            };

            setMessages((prevMessages) => [...prevMessages, botMessage]);
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err,"11");
        setLoader(false);
        setDisabled(false);
        setTimeout(() => {
          const botMessage = {
            content: "Error",
            sender: "bot",
            side: "left",
            url: null,
            pageNo: null,
            DocUrl: null,
            copytext: null,
            file_name: null,
            start_time: null
          };

          setMessages((prevMessages) => [...prevMessages, botMessage]);
        });
      });
  };
  const loadingImageRef = useRef(null);

  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  console.log(messages.file_name, "fileName")

  useEffect(() => {
    // Fetch available voices when the component mounts
    // const availableVoices = window.speechSynthesis.getVoices();
    // console.log(availableVoices, 'availableVoices');
    // setVoices(availableVoices);

    const handleVoicesChanged = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
    };

    // Attach the event listener to the speechSynthesis object
    window.speechSynthesis.onvoiceschanged = handleVoicesChanged;

    // Fetch initial available voices
    handleVoicesChanged();

    // Clean up the event listener when the component unmounts
    return () => {
      window.speechSynthesis.onvoiceschanged = null;
    };
  }, []);
  console.log(voices, "availableVoices");
  console.log(selectedVoice, "selectedVoices");

  const selectMaleVoice = () => {
    // const maleVoice = voices.find((voice) => voice.name.includes("Microsoft David - English (United States)"));
    const maleVoice = voices.find((voice) => voice.name == "Google US English");
    // const maleVoice = voices.find((voice) => voice.name == 'Microsoft Mark - English (United States)') || voices.find((voice) => voice.name == 'Microsoft Mark - English (United States)');
    // const maleVoice = voices.find((voice) => voice.gender == 'male');

    if (maleVoice) {
      setSelectedVoice(maleVoice);
    } else {
      console.log("No male voices available.");
    }
  };

  useEffect(() => {
    // When available voices change, select a male voice
    selectMaleVoice();
  }, [voices]);

  const handleSpeech = (content, index) => {
    const textToSpeak = content;
    // const maxLength = 200;
    // const segments = [];

    const segments = textToSpeak.split(/(?<=\.\s+)/); // Split the text at periods followed by spaces OR periods, exclamation marks, and question marks

    segments.forEach((segment, index) => {
      const trimmedSegment = segment.trim(); // Remove leading/trailing whitespace
      if (trimmedSegment) {
        const speech = new SpeechSynthesisUtterance(trimmedSegment);
        speech.voice = selectedVoice;
        speech.rate = 1; // Adjust the speech rate as needed

        // Add an event listener to detect when speech ends
        speech.addEventListener("end", () => {
          // This code will execute when each segment finishes speaking
          if (index === segments.length - 1) {
            // This is the last segment
            setSpeakStates(false);
          }
        });

        // Start speaking the segment
        window.speechSynthesis.speak(speech);
      }
    });

    ////////////////////////Working code 2.0 but the sentence us breaking/////////////////
    // const maxSegmentLength = 100; // Maximum length for a single speech segment
    // const segments = [];
    // const words = textToSpeak.split(/\s+/); // Split the text into words

    // let currentSegment = "";

    // for (const word of words) {
    //   if (currentSegment.length + word.length + 1 <= maxSegmentLength) {
    //     // Add the word to the current segment if it doesn't exceed the limit
    //     if (currentSegment) {
    //       currentSegment += " ";
    //     }
    //     currentSegment += word;
    //   } else {
    //     // If adding the word would exceed the limit, start a new segment
    //     segments.push(currentSegment);
    //     currentSegment = word;
    //   }
    // }

    // // Add the last segment
    // if (currentSegment) {
    //   segments.push(currentSegment);
    // }

    // segments.forEach((segment, index) => {
    //   const speech = new SpeechSynthesisUtterance(segment);
    //   speech.voice = selectedVoice;
    //   speech.rate = 0.8; // Adjust the speech rate as needed

    //   // Add an event listener to detect when speech ends
    //   speech.addEventListener("end", () => {
    //     // This code will execute when each segment finishes speaking
    //     if (index === segments.length - 1) {
    //       // This is the last segment
    //       setSpeakStates(false);
    //     }
    //   });

    //   // Start speaking the segment
    //   window.speechSynthesis.speak(speech);
    // });
    ////////////////////////Working code 2.0 but the sentence us breaking/////////////////

    ////////////////////////////////////// Working code above but the words are getting splitted
    // for (let i = 0; i < textToSpeak.length; i += maxLength) {
    //   segments.push(textToSpeak.substring(i, i + maxLength));
    // }

    // segments.forEach((segment, index) => {
    //   const speech = new SpeechSynthesisUtterance(segment);
    //   speech.voice = selectedVoice;
    //   setSpeakStates(true);

    //   // Listen for the 'end' event and set speak to false when speech completes
    //   speech.addEventListener("end", () => {
    //     if (index === segments.length - 1) {
    //       // This is the last segment
    //       setSpeakStates(false);
    //     }
    //   });
    //   window.speechSynthesis.speak(speech);
    // });

    ////////////////////////////////////// Working code above but the words are getting splitted

    // const speech = new SpeechSynthesisUtterance(content);
    // speech.voice = selectedVoice;
    // speechSynthesis.speak(speech);

    // const speech = new SpeechSynthesisUtterance(content);

    // // Listen for the 'end' event and set speak to false when speech completes
    // speech.addEventListener("end", () => {
    //   setSpeakStates((prevStates) => ({
    //     ...prevStates,
    //     [messageId]: false,
    //   }));
    // });

    // speechSynthesis.speak(speech);

    // // Reset the speak state for all other messages
    // const updatedSpeakStates = Object.keys(speakStates).reduce((acc, id) => {
    //   return { ...acc, [id]: id === messageId ? true : false };
    // }, {});
    // setSpeakStates(updatedSpeakStates);
  };

  const stopSpeech = (messageId) => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setSpeakStates(false);
    }

    // if (speechSynthesis.speaking) {
    //   speechSynthesis.cancel();
    //   setSpeakStates((prevStates) => ({
    //     ...prevStates,
    //     [messageId]: false,
    //   }));
    // }
  };

  const sendFeedback = (index, val, message) => {
    setMessages((prevMessages) =>
      prevMessages.map((message, i) =>
        i === index ? { ...message, feedback: val } : message
      )
    );
    console.log(message.content, "valye");
    let url = `${BaseUrl}/user_action?user_id=${UserId}&bot_id=${BotID}&response=${message.copytext}&action=${val}`;
    axios.post(url).then((res) => {
      console.log(res.data, "userInput");
    });
  };

  useEffect(() => {
    if (loadingImageRef.current && Loader) {
      loadingImageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [Loader]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (e, val) => {
    setIsModalOpen(true);
    // let filename = e.split('/').slice(-1);
    // let convertedFileName = filename[0].replace(/ /g, '+');
    const filename = e.substring(e.lastIndexOf('/') + 1);

    // Replace spaces with '+'
    const modifiedFilename = filename.replace(/ /g, '+');

    // Construct the new link with the modified filename
    const newLink = e.replace(filename, modifiedFilename);

    const updateFileName = newLink.replace(/ded2w7tw5rnvn.cloudfront.net/, 'preciumweb-dev.s3.amazonaws.com')

    setDOCURL(updateFileName);

    setPageNumber(parseInt(val));

    console.log(updateFileName, "DOCURL");
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen(false);
  };

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {

    setNumPages(numPages);
  };
  // console.log('Number of pages:', numPages);
  console.log(numPages, "numPages");
  const [isthumbsDown, setisthumbsDown] = useState(false);
  console.log(SelectedFeedback.label, "SelectedFeedback");
  const thumbsDownOK = () => {
    setMessages((prevMessages) =>
      prevMessages.map((message, i) =>
        i === Index ? { ...message, feedback: false } : message
      )
    );
    console.log(SelectedFeedback, "SelectedFeedback");
    let url = `${BaseUrl}/user_action?user_id=${UserId}&bot_id=${BotID}&response=${ThumbsDownres}&action=false&reason=${SelectedFeedback}&expected_response=${comments}`;
    axios
      .post(url)
      .then((res) => {
        setisthumbsDown(false);
        console.log(res.data, "userInput");
        if (res.data.message == "Action updated successfully!") {
          setDisLikeClass("active");
          setLikeClass("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const thumbsDownCancel = () => {
    setisthumbsDown(false);
  };

  const ShowThumbsDown = (e, val, message) => {
    setisthumbsDown(true);
    setIndex(e);
    console.log(e, "ShowThumbsDown", message.copytext);
    setThumbsDownres(message.copytext);
  };

  // const ShowThumbsDown = (e, val, message) => {
  //   if (dislikeClass === "") {
  //     setisthumbsDown(true);
  //   } else {
  //     setisthumbsDown(false);
  //     setDisLikeClass('');
  //     setLikeClass('');
  //     setMessages((prevMessages) =>
  //       prevMessages.map((message, i) =>
  //         i === Index ? { ...message, feedback: null } : message
  //       )
  //     );
  //     console.log(SelectedFeedback, "SelectedFeedback");
  //     let url = `${BaseUrl}/user_action?user_id=${UserId}&bot_id=${BotID}&response=${ThumbsDownres}&action=null&reason=${SelectedFeedback}`;
  //     axios
  //       .post(url)
  //       .then((res) => {
  //         setisthumbsDown(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  //   setIndex(e);
  //   console.log(val, "ShowThumbsDown");
  //   setThumbsDownres(message.content);
  // };

  const handleKeyPress = (e) => {
    console.log(e, "key", e.key === 'Enter' && e.shiftKey)
    if (e.key === 'Enter' && e.shiftKey) {
      // Insert a newline character without submitting the form
      e.preventDefault();
      setInputVal(inputVal + '\n');
    }
    // Define a regular expression to allow only alphanumeric characters and spaces
    // const regex = /^[a-zA-Z0-9\s]*$/;
    const regex = /^[a-zA-Z0-9 !@#$%^&*()_+{}\[\]:;<>,.=`'"?/~\\/-]+$/;

    // Check if the pressed key's character matches the regular expression
    if (!regex.test(e.key)) {
      // If it's a special character, prevent it from being entered
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const pastedText = (e.clipboardData || window.clipboardData).getData(
      "text"
    );

    const regex = /^[a-zA-Z0-9 !@#$%^&*()_+{}\[\]:;<>,.=`'"?/~\\/-]+$/;

    if (regex.test(pastedText)) {
      setInputVal(pastedText);
    }
  };

  useEffect(() => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = false;
    recognition.lang = "en-US";

    recognition.onresult = (event) => {
      handleSpeechResult(event);
    };

    recognition.onerror = (event) => {
      console.log("Speech recognition error:", event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      console.log("Speech recognition ended");
      setIsListening(false);
    };

    recognitionRef.current = recognition;

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, []);

  // const startListening = () => {
  //   setIsListening(true);

  //   if (recognitionRef.current) {
  //     recognitionRef.current.start();
  //   }
  // };

  // const stopListening = () => {
  //   setIsListening(false);

  //   if (recognitionRef.current) {
  //     recognitionRef.current.stop();
  //   }
  // };

  // const handleSpeechResult = (event) => {
  //   const { transcript } = event.results[0][0];
  //   setInputVal(transcript);
  //   // setIsListening(false);
  // };

  // useEffect(() => {
  //   const startListening = () => {
  //     annyang.start();
  //     setIsListening(true);
  //   };

  //   const stopListening = () => {
  //     annyang.abort();
  //     setIsListening(false);
  //   };

  //   // Add a callback for the result event
  //   annyang.addCallback('result', (phrases) => {
  //     setTranscription(phrases[0]);
  //   });

  //   // Clean up on component unmount
  //   return () => {
  //     annyang.abort();
  //   };
  // }, []);

  // // console.log(transcription,'annyangPhrases')

  // const voiceHandle = () => {
  //   if (isListening) {
  //     // Stop listening if currently listening
  //     annyang.abort();
  //     setIsListening(false);
  //     // setInputVal("");
  //     // setTranscription("");
  //   } else {
  //     // Start listening if not currently listening
  //     annyang.start();
  //     setIsListening(true);
  //     setInputVal(transcription);
  //   }
  // }

  console.log(Feedback[0], "Feedback");

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async (e) => {
    console.log(e, "copy");
    try {
      await copy(e);
      setIsCopied(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const preventCopyPaste = (e) => {
    e.preventDefault();
    openNotificationWithIconError("Pasting is not allowed!");
  }

  const handleEBVtoClick= (event) =>{
    window.open(event, '_blank');
  }
  const handleMailtoClick = (event) => {
    let template = `
    Hi,
    Please find the details you have requested to complete my enrollment.
   
    Here are my details 
    Please find the request details for my enrollment 
   Lead_id:${LeadId}
    Email: 
    
   
    Thank you
    `;
    
  
    event.preventDefault();
    window.location.href = `mailto:${MailId}?subject=Enrollment Completion &body=${template}`;
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  return (
    <div className="botWidgetModals">
      {contextHolder}
      <ImagePreviewModal
        className="previewmodal"
        visible={previewVisible}
        onCancel={handleCancel}
        imageUrl={previewImage}
        filename={previewFilename}
      />
      <Modal
        className="Modal1"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel1}
        footer={null}
      >
        <div>
          <Document
            file={DOCURL}
            onLoadSuccess={onDocumentLoadSuccess}>
            {
              PageNumber === 0 ? (
                Array.from({ length: numPages }, (_, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))
              )
                :
                <Page pageNumber={PageNumber} />


            }
          </Document>
          {/* <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
        <Viewer fileUrl={DOCURL}pageNumber={PageNumber}  />
      </Worker> */}


        </div>
      </Modal>
      <Modal
        className="previewmodal"
        open={isthumbsDown}
        onOk={thumbsDownOK}
        onCancel={thumbsDownCancel}
      >
        <h3>Response</h3>
        <p>{ThumbsDownres}</p>
        <h3>Feedback</h3>

        <Select
          style={{ minWidth: 100, width: "50%" }}
          onChange={(e, val) => setSelectedFeedback(val.label)}
          defaultValue={Feedback[0]}
          value={SelectedFeedback}
          options={Feedback}
        />
        <h3 style={{ marginTop: 10 }}>Comments</h3>
        <TextArea
          onChange={(e) => setComments(e.target.value)}
          value={comments}
          placeholder="Place your comments"
          rows={4}
          style={{ resize: "none" }}
        />
      </Modal>

      {Loading === false ? (
        <Row style={{ height: "100vh" }}>
          {/* <Col span={4}>
            <ChatBotSider />
          </Col> */}
          <Col span={24}>
            <div className="chatbot">
              <div
                className="chatbot__header"
                style={{ backgroundColor: BubbleClr }}
              >
                <Row>
                  <Col span={20}>
                    <p className="botname">{BotName}</p>
                  </Col>

                  <Col span={4}>
                    <img
                      className="closebutton"
                      style={{ height: "20px", width: "20px" }}
                      onClick={handleClose}
                      src="https://flaticons.net/icon.php?slug_category=mobile-application&slug_icon=close"
                      alt="Chatbot"
                    />
                  </Col>
                </Row>
              </div>

              <div className="chatbot__messages" ref={chatWindowRef}>
                <Row>
                  <div className="message message--bot">
                    <div className="botResponseDiv">
                      <img
                        src={!ChatImg ? "/Logo/P-Logo.png" : ChatImg}
                        width={35}
                        height={35}
                      />
                      <p className="answer-Text">{welcomeMsg}</p>
                    </div>
                  </div>
                </Row>
                {messages.map((message, index) => {
                  let url = message.DocUrl;
                  let sourceFileName = url?.split('/').slice(-1)[0];

                  console.log(sourceFileName, 'messageFeedback')
                  const lastIndex = messages.length - 1;
                  return (
                    <Row key={index} className={`${message.side}PositionDiv`}>
                      <div className="messageBubbleWidth">
                        <div
                          className={`${message.sender}-Div`}
                          // onMouseEnter={() =>
                          //   message.sender === "bot"
                          //     ? handleLikeDisplay(index)
                          //     : null
                          // }
                          // onMouseLeave={(index) =>
                          //   message.sender === "bot"
                          //     ? handleLikeOutDisplay(index)
                          //     : null
                          // }
                          style={{
                            backgroundColor:
                              // message.sender === "question" ? Bubbleclr : "#ffffff",
                              message.sender === "user" ? "#3d3d3d" : "#2b2b2b",
                          }}
                        >
                          <div className="botResponseDiv">
                            {message.sender === "bot" ? (
                              <>
                                <img
                                  src={!ChatImg ? "/Logo/P-Logo.png" : ChatImg}
                                  width={35}
                                  height={35}
                                />
                              </>
                            ) : (
                              <img
                                src="/Logo/user-logo-3.png"
                                width={35}
                                height={35}
                              />
                            )}
                            <p
                              // dangerouslySetInnerHTML={{ __html: message.content }}
                              className={`${message.sender}-Text`}
                              style={{
                                color:
                                  //   message.sender === "question" ? MessageClr : "#000000",
                                  message.sender === "user"
                                    ? "#ffffff"
                                    : "#ffffff",
                              }}
                            >
                              {message.sender == "bot" ? (
                                <>
                                  {message.url != null ? (
                                    <Tooltip
                                      placement="right"
                                      title="view source"
                                    >
                                      <InfoCircleOutlined
                                        className="viewSrc"
                                        onClick={() =>
                                          window.open(message.url, "_blank")
                                        }
                                      />
                                    </Tooltip>
                                  ) : (
                                    <></>
                                  )}
                                  <TypeWriter text={message.content} />
                                  {message.pageNo != null &&
                                    reference == true ? (
                                    <>
                                      <p
                                        onClick={(e) =>
                                          showModal(
                                            message.DocUrl,
                                            0
                                          )
                                        }
                                        className="pagenumber"
                                        style={{
                                          color: BubbleClr,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {/* Refer page number : {message.pageNo} */}
                                        Source : {sourceFileName !== "" ? sourceFileName : "No name available!"}.
                                      </p>
                                      <p
                                        onClick={(e) =>
                                          showModal(
                                            message.DocUrl,
                                            message.pageNo
                                          )
                                        }
                                        className="pagenumber"
                                        style={{
                                          color: BubbleClr,
                                          cursor: "pointer",
                                        }}
                                      >Reference page number - {message.pageNo}</p>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {
                                    message.mailId != null ?
                                    <>
                                    <p>
                                      Due to missing information ,Please Click the below link to complete the enrollment
                                    </p>
                                      <Button onClick={handleMailtoClick} >
                                        Send Mail
                                      </Button>
                                      </>
                                      :
                                      <></>
                                  }
                                   {
                                    message.ebvLink != null ?
                                    <>
                                    <p>
                                      Please Click to initiate Prior Auth
                                    </p>
                                      <Button onClick={() => handleEBVtoClick(message.ebvLink)} >
                                        Open in new window
                                        
                                      </Button>
                                      </>
                                      :
                                      <></>
                                  }

                                  {
                                    // isHovered != false &&
                                    message.sender === "bot" ? (
                                      // &&
                                      // index === hoveredRow
                                      <div className="feedBackImageDiv">
                                        <div className="feedBackImageInsideDiv">
                                          <div
                                            style={{
                                              display: "flex",
                                              // justifyContent: "space-between",
                                            }}
                                          >
                                            <div style={{ display: "flex" }}>
                                              <div
                                                className={`feedbackGaveInsideDiv ${message.feedback === true
                                                  ? "active"
                                                  : "notActive"
                                                  }-Likes`}
                                                onClick={() => {
                                                  if (message.feedback === true) {
                                                    sendFeedback(index, null, message);
                                                  } else {
                                                    sendFeedback(index, true, message);
                                                  }
                                                }}
                                              >
                                                <ThumbUpAltOutlinedIcon />
                                              </div>
                                              <div
                                                className={`feedbackGaveInsideDiv ${message.feedback === false
                                                  ? "active"
                                                  : "notActive"
                                                  }-disLikes`}
                                                onClick={() => {
                                                  if (message.feedback === false) {
                                                    sendFeedback(index, null, message);
                                                  } else {
                                                    ShowThumbsDown(
                                                      index,
                                                      false,
                                                      message
                                                    );
                                                  }
                                                }}
                                              >
                                                <ThumbDownAltOutlinedIcon />
                                              </div>
                                            </div>
                                            <div>
                                              {speakStates == false ? (
                                                <FontAwesomeIcon
                                                  onClick={() => {
                                                    handleSpeech(
                                                      message.copytext,
                                                      index
                                                    );
                                                    setSpeakStates(true);
                                                  }}
                                                  icon={faVolumeHigh}
                                                  className="botsSpeakerClass"
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  onClick={() => {
                                                    stopSpeech(index);
                                                    setSpeakStates(false);
                                                  }}
                                                  icon={faVolumeHigh}
                                                  className="botsSpeakerClass"
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        {message.sender === "bot" && (
                                          <div className="feedBackImageDiv">
                                            <div className="feedBackImageInsideDiv">
                                              {/* {speakStates == false ? ( */}
                                              <FontAwesomeIcon
                                                onClick={() => {
                                                  handleSpeech(message.copytext, index);
                                                  setSpeakStates(true);
                                                }}
                                                icon={faVolumeHigh}
                                                className="botsSpeakerClassDummy"
                                              />

                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )
                                  }
                                  {message.sender === "bot" ? (
                                    <>
                                      <FontAwesomeIcon
                                        className="copyIcon"
                                        onClick={() => handleCopyClick(message.copytext)}
                                        icon={faCopy}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {Suggession.map((m, i) => {
                                    console.log(m, "suggestedQuestions");
                                    console.log(i, "indexofMessage");
                                    return (
                                      <div className="SuggestedQuestions">
                                        {index === lastIndex && m != "" && (
                                          <Button
                                            disabled={Disabled}
                                            value="hi"
                                            onClick={(e) =>
                                              sendMessage(e.target.innerText)
                                            }
                                            className="SuggestedQuestionsBTN"
                                          >
                                            {m}
                                          </Button>
                                        )}
                                      </div>
                                    );
                                  })}
                                  {
                                    message.file_name != null ?
                                      <p
                                        onClick={(e) =>
                                          showModal(message.file_name, message.start_time,)
                                        }
                                        className="pagenumber"
                                        style={{ color: BubbleClr, cursor: 'pointer' }}
                                      >
                                        Show Source Video
                                        <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faPlay} />
                                      </p>
                                      :
                                      <></>
                                  }
                                </>
                              ) : (
                                <p>{HTMLReactParser(message.content)}</p>
                              )}
                            </p>
                          </div>




                          {/* <p className="dayTimeClassAnswer">{message.time}</p> */}

                          {/* {message.sender === "bot" &&
                          message.feedback === false ? (
                            <div
                              className="feedbackGaveDiv"
                              // ref={chatBotref}
                            >
                              <div
                                className="feedbackGaveInsideDiv"
                                onClick={() =>
                                  sendFeedback(index, null, message)
                                }
                              >
                                <img
                                  src="/Emoji/thumbsDown.png"
                                  width={19}
                                  height={18}
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )} */}
                          {/* {message.sender === "bot" &&
                          message.feedback === true ? (
                            <div
                              className="feedbackGaveDiv"
                              // ref={chatBotref}
                            >
                              <div
                                className="feedbackGaveInsideDiv"
                                onClick={() =>
                                  sendFeedback(index, null, message)
                                }
                              >
                                <img
                                  className=""
                                  src="/Emoji/thumbsUp.png"
                                  width={19}
                                  height={18}
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )} */}

                          {message.sender === "bot" && (
                            <>
                              {/* ==================  Text to Speech ====================== */}
                              {/* <div>
                                {speakStates == false ? (
                                  <div className="feedbacks">
                                    <FontAwesomeIcon
                                      onClick={() => {
                                        handleSpeech(message.content, index);
                                        setSpeakStates(true);
                                      }}
                                      className="speakerIcons"
                                      icon={faVolumeHigh}
                                    />
                                  </div>
                                ) : (
                                  <div className="feedbacks">
                                    <FontAwesomeIcon
                                      onClick={() => {
                                        stopSpeech(index);
                                        setSpeakStates(false);
                                      }}
                                      className="speakerIcons"
                                      icon={faVolumeHigh}
                                    />
                                  </div>
                                )}
                              </div> */}
                            </>
                          )}
                        </div>
                      </div>
                    </Row>
                  );
                })}
                {Loader === true ? (
                  <div className="leftPositionDiv">
                    {" "}
                    <div className="bot-Div" ref={loadingImageRef}>
                      <div className="botResponseDiv">
                        <img
                          src={!ChatImg ? "/Logo/P-Logo.png" : ChatImg}
                          width="35"
                          height="35"
                        />
                        <div className="loading-animation">
                          <div className="loading-dots">
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {/* {Loader === true ? (
                <div className="message message--bot">
                  <div className="answer-Text" ref={loadingImageRef}> */}
              {/* <img src='bot-3.png' alt='bot-img' width='40px' height='40px' style={{ marginBottom: "13px" }} />  */}
              {/* <img
                      style={{ height: "70px", width: "100px" }}
                      src="Loading.gif"
                      alt=""
                    />
                  </div>
                </div>
              ) : (
                <></>
              )} */}

              {/* <div className="SuggestedQuestions">
                {Suggession.map((m) => {
                  return (
                    <Tooltip placement="top" title={m}>
                      <Button
                        disabled={Disabled}
                        value="hi"
                        onClick={(e) => sendMessage(e.target.innerText)}
                        className="SuggestedQuestionsBTN"
                      >
                        {m}
                      </Button>
                    </Tooltip>
                  );
                })}
              </div> */}
              {
                isListening &&
                <div className="micAnimation">
                  <div className="micAnimationDiv" style={{ display: 'flex' }}>
                    <Lottie
                      className='lottiePlayer'
                      loop={true}
                      style={{ width: '50px', height: '50px' }}
                      animationData={micAnimation}
                      play
                      direction="1"
                      speed="1"
                    ></Lottie>
                    <p className="micAnimationText">Ask me anything in English! As I can only understand English</p>
                  </div>
                </div>
              }
              {isCopied &&
                <div className="Copied"> <p>Copied!</p></div>
              }
              <div className="chatbot__form">
                {/* <div className="botInputDiv"> */}

                <TextArea
                  autoSize={{ minRows: 1, maxRows: 4 }}
                  // onKeyDown={ (e) => {
                  //     e.preventDefault();
                  //     if(e.key ==='Enter') {
                  //         handleSubmit();
                  // } } }
                  // rows={1}
                  disabled={Disabled}
                  onKeyDown={handleKeySubmit}
                  value={inputVal}
                  onKeyPress={handleKeyPress}
                  // onPaste = { preventCopyPaste }
                  // onPaste={handlePaste}
                  // disabled={Disabled}
                  style={{ marginRight: '-2px' }}
                  onChange={handleInput}
                  className="Bot-input"
                  placeholder="Enter Your Message"
                />
                {/* <img
                  className="sendButton"
                  src="/Vector.svg"
                  onClick={handleSubmit}
                ></img> */}
                <div
                // style={{
                //   width: '100%',
                //   height: `${waveHeight}px`,
                //   backgroundColor: '#3498db',
                //   position: 'relative',
                //   overflow: 'hidden',
                // }}
                >
                  <FontAwesomeIcon
                    onClick={isListening ? stopListening : startListening}
                    icon={faMicrophone}
                    className={isListening ? "voiceButtonRed" : "voiceButton"}
                  />
                </div>
                <FontAwesomeIcon
                  // style={{ color: BubbleClr }}
                  style={{ color: "#d1d1d1" }}
                  icon={faPaperPlane}
                  onClick={handleSubmit}
                  className="sendButton"
                />
                {/* <Upload
                  onChange={handlePreview}
                  showUploadList={false}
                  accept={acceptFile}
                  customRequest={({ onSuccess }) => onSuccess("ok")}
                  className="Upload"
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="uploadIcon"
                    onClick={() => setErrorNotification(null)}
                  />
                </Upload> */}
              </div>

              <p className="poweredby">
                Powered By
                <a
                  style={{ textDecoration: "none" }}
                  href={`https://dev-webapp.precium.ai/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    style={{
                      color: "aliceblue",
                      textDecoration: "none",
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    Precium
                  </span>
                </a>
              </p>
            </div>
          </Col>
        </Row>
      ) : (
        <div className="loading">
          <img src="/loader/tail-spin.svg" className="loaderImage" />
        </div>
      )}
    </div>
  );
};

export default Chatbot;
